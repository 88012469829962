export const standardGutter = 8
const baseDesktop = 1080
const padding = standardGutter * 4

const SvrStyle = {
  color: {
    primary: '#ecf4fb',
    secondary: '#3a4e5f',
    black: '#092238',
    grey: '#858E95',
    greySecondary: '#c2c6ca',
    greyMuted: '#e0e2e6',
    successAccent: '#00c08e',
    warningAccent: '#ff943d',
    warningBackground: '#fff4ec',
    secondaryText: '#6b6b6b',
    errorAccent: '#f3356b',
    default: '#ebedf0',
    white: '#ffffff',
    lightGrey: '#f7f7f7',
    lightPink: '#eab58c',
    rowHover: '#d1eaff',
    search: '#3992d4',
    disclaimer: '#6B6B6B',

    blue: '#5493cc',
    // Used for sizzle
    positive: '#54b9c0',
    neutral: '#898c91',
    negative: '#e04a6e',
    teal: '#0abcc2',
    lightGreen: '#9ed4b4',
  },
  screenSizes: {
    tiny: '400px',
    xtiny: '325px',
    mobile: '480px',
    thin: '600px',
    tablet: '768px',
    desktop: `${baseDesktop}px`,
    maxDesktop: `${baseDesktop + padding * 2}px`,
  },
  fontSize: {
    xLarge: '72px',
    large: '48px',
    display: '36px',
    title: '33px',
    largeSubheader: '26px',
    subheader: '22px',
    medium: '18px',
    body: '15px',
    button: '16px',
    caption: '13px',
    small: '12px',
    xsmall: '11px',
    xxsmall: '10px',
    xxxsmall: '8px',
  },
  shadow: {
    one: '0 2px 4px 0 rgba(9,34,56,0.1)',
    two: '0 4px 8px 0 rgba(9,34,56,0.1)',
    three: '0 8px 16px 0 rgba(9,34,56,0.1)',
    four: '0 16px 24px 0 rgba(9,34,56,0.1)',
    five: '0 24px 32px 0 rgba(9,34,56,0.1)',
    six: '0 1px 0 0 #d8d8d9',
  },
  padding: {
    xLarge: '32px',
    large: '24px',
    medium: '16px',
    small: '8px',
    xSmall: '4px',
  },
  gutterInt: {
    half: standardGutter / 2,
    standard: standardGutter,
    medium: standardGutter + standardGutter / 2,
    double: standardGutter * 2,
    triple: standardGutter * 3,
  },
  gutter: {
    half: `${standardGutter / 2}px`,
    standard: `${standardGutter}px`,
    medium: `${standardGutter + standardGutter / 2}px`,
    double: `${standardGutter * 2}px`,
    triple: `${standardGutter * 3}px`,
    quadruple: `${standardGutter * 4}px`,
  },
}

const BaseTheme = {
  ...SvrStyle,
  primary: '#347baf',
  primaryRgba10: 'rgba(62,147,209,0.1)',
  primaryRgba20: 'rgba(62,147,209,0.2)',
  primaryHover: '#2c75aa',
  primarySelected: '#235c86',
  navigationDark: '#092238',
  navigationDarkRgba60: 'rgba(9,34,56,0.6)',
  navigationLight: '#3b5165',
  mediumSilver: '#acb5bd',
  lightSilver: '#dee1e4',
  lighterSilver: '#ebedf0',
  margin: '#e6e8eb',
  background: '#ffffff',
  error: '#f3356b',
  warning: '#ff943d',
  success: '#00c08e',
  active: '#5fca86',
  inactive: '#f5706e',

  // Neutrals
  black: '#0a0a0a',
  charcoal: '#47484c',
  darkGrey: '#9a9b9c',
  mediumGrey: '#cccccc',
  lightGrey: '#dedede',
  extraLightGrey: '#eeeeee',
  white: '#ffffff',

  // Misc
  yellow: '#ffdd00',
  fontFamily: 'Roboto, sans-serif',
  headerFontFamily: 'Oswald, sans-serif',

  mediaUrl: window.globalInit && window.globalInit.mediaUrl,
}

const ellipsis = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

// Common style bits
const Theme = {
  ...BaseTheme,
  ellipsis,
  twoLineEllipsis: `
    ${ellipsis}

    @supports (-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
  `,
  clearFix: `
    ::after {
        content: "";
        display: block;
        clear: both;
    }
  `,
  primaryButton: `
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto, arial, sans-serif;
    text-align: center;
    color: ${BaseTheme.white};
    background-color: ${BaseTheme.primary};
  `,
  primaryTealButton: `
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto, arial, sans-serif;
    text-align: center;
    color: ${BaseTheme.white};
    background-color: ${BaseTheme.color.teal};
  `,
  secondaryButton: `
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto, arial, sans-serif;
    text-align: center;
    color: ${BaseTheme.charcoal};
    background-color: ${BaseTheme.extraLightGrey};
  `,
  secondaryTealButton: `
    cursor: pointer;
    padding: 0;
    border: 1px solid ${BaseTheme.color.teal};
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto, arial, sans-serif;
    text-align: center;
    color: ${BaseTheme.color.teal};
    background-color: ${BaseTheme.white};
  `,
  deleteButton: `
    cursor: pointer;
    padding: 0;
    border: 1px solid ${BaseTheme.error};
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto, arial, sans-serif;
    text-align: center;
    color: ${BaseTheme.error};
    background-color: ${BaseTheme.background};
  `,
  whiteClickableRow: `
    cursor: pointer;
    :hover {
      background-color: ${BaseTheme.primaryRgba10};
    }
  `,
  autoFontSmoothing: `
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  `,
  fontWeight300: `
    font-weight: 300;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  `,
}

export type ManagerDefaultTheme = typeof Theme

export default Theme
