import React from 'react'
import styled, { css } from 'styled-components'

const base = 1080
const gutter = 8
const padding = gutter * 4
const maxDetails = 700
const maxMobile = 860

export const screenSizes = {
  desktop: base,
  maxDesktop: base + padding * 2,
  maxDetails,
  maxMobile,
  carouselHeight: 466,
  twentytwenty: {
    carousel: base - gutter * 4,
  },
  tiny: 400,
  large: 1318,
  largeMin: 844,
  largeMax: 1318,
  iphone6: 375,
  iphone6plus: 411,
  xtiny: 325,
  mobile: 420,
  tablet: 800,
}

export const LimitedContainer = styled.div`
  display: flex;
  width: ${screenSizes.desktop}px;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.color.white};
  padding: 0 ${props => props.theme.gutter.double};

  ${props =>
    props.overlayed &&
    css`
      margin: ${padding * 3}px 0;
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0.1) 0 3px 3px;
      border-radius: 6px;
      overflow: hidden;
      z-index: 1;
      position: relative;

      @media (max-width: ${screenSizes.maxDesktop}px) {
        margin-top: ${props => props.theme.gutter.double};
        margin-bottom: 0;
      }
    `}

  @media (max-width: ${screenSizes.maxDesktop}px) {
    width: 100%;
    justify-content: center;
    padding: ${props => props.theme.gutter.double};
    flex-direction: column;
  }
`
