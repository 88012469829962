import * as ActionTypes from '../actions/ActionTypes'

const initialState = {
  showBookingModal: false,
  experienceModalId: null,
}

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_EXPERIENCE_BOOK:
      return {
        ...state,
        showBookingModal: !state.showBookingModal,
      }
      break
    case ActionTypes.TOGGLE_EXPERIENCE_MODAL:
      return {
        ...state,
        experienceModalId: action.experienceId,
      }
      break
    default:
      return state
  }
}

export default bookingReducer
